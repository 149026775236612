/* .cls-1, .cls-2 {
    fill: #ffffff;
}
.cls-1 {
    stroke: #000000;
    stroke-miterlimit: 10;
}
.cls-3 {
    fill: #c1883e;
} */
.line {
    width: 2px;
    position: absolute;
    left: 12.4%;
    z-index: -1;
}
.rectangle {
    /* width: 356px; */
    width: 18.54vmax;
    /* height: 3000px; */
    height: 156vw;
    position: absolute;
    left: 70%;
    z-index: -1;
}
.portfolio h1 {
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
    margin-top: 4%;
    margin-bottom: 4%;
}
.photo-container {
    /* width: 1024px; */
    width: 60%;
    margin: auto;
}
.photo-container img {
    /* width: 173px;
    height: 155px; */
    width: 9vmax;
    height: 8vmax;
    object-fit: cover;
    cursor: pointer;
    margin-bottom: 20%;
}
.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, .86) !important;
}
.portfolio-arrows {
    position: fixed;
    width: 90%;
    display: flex;
    justify-content: space-between;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 2000;
}
.portfolio-arrows svg {
    width: 5%;
    cursor: pointer;
    height: 5%;
}
.right-arrow {
    transform: rotateY(180deg);
}
.gallery img {
    width: 100%;
    overflow: hidden;
}
.before-after img {
    width: 100%;
    overflow: hidden;
}
.before-after {
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    z-index: 2000;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.before-after img {
    width: 90%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}
.before-after p {
    text-align: center;
    color: white;
    font-size: 1.5rem;
    margin-bottom: 5%;
}
/* .before-after-p {
    display: flex;
    justify-content: space-around;
    width: 31%;
    color: white;
    font-size: 1.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
} */
/* .before {
    color: white;
    position: absolute;
    left: 25%;
    transform: translate(-50%);
    z-index: 2000;
    bottom: 100%;
}
.after {
    color: white;
    position: absolute;
    left: 75%;
    transform: translate(-50%);
} */

/* @media screen and (max-width: 1500px) {
    .rectangle {
        height: 160vw;
    }
} */

@media screen and (max-width: 1366px) {
    .portfolio h1 {
        font-size: 1.725rem;
    }
    .before-after p {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 768px) {
    .photo-container {
        width: 70%;
    }
    .portfolio-arrows {
        width: 98%;
    }
    .before-after {
        display: block;
        z-index: 2000;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .before-after p {
        font-size: 1.25rem;
        margin-bottom: 2%;
        margin-top: 4%;
    }
}

@media screen and (max-width: 414px) {
    .portfolio h1 {
        font-size: 1.375rem;
        margin-top: 7%;
        margin-bottom: 10%;
    }
    .photo-container {
        /* width: 1024px; */
        width: 75%;
        margin: auto;
    }
    .photo-container img {
        /* width: 173px;
        height: 155px; */
        width: 14vmax;
        height: 13vmax;
    }
    .before-after p {
        font-size: 0.875rem;
        margin-bottom: 4%;
        margin-top: 6%;
    }
}