.footer {
    width: 100%;
    background-color: #3D3D3D;
    margin-bottom: 0;
    height: 160px;
    text-align: center;
    margin-top: 5%;
}
.footer p {
    color: white;
    font-size: 1rem;
    padding-bottom: 1em;
    cursor: pointer;
    width: fit-content;
    margin: auto;
}
.footer svg {
    width: 2.5%;
    cursor: pointer;
}
.footer-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@media screen and (max-width: 1280px) {
    .footer {
        height: 130px;
    }
}

@media screen and (max-width: 768px) {
    .footer {
        height: 110px;
    }
    .footer svg {
        width: 4%;
    }
}

@media screen and (max-width: 414px) {
    .footer {
        height: 80px;
    }
    .footer p {
        padding-bottom: 0.75em;
        font-size: 0.75rem;
    }
    .footer svg {
        width: 6%;
    }
}