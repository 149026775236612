.homepage {
    background-image: url('../images/full-interior.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    margin-top: 0;
}
.overlay {
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0, .73);
}
.homepage-container {
    width: 80%;
    height: 80vh;
    margin: auto;
    margin-top: 3%;
    border: solid #C1883E 2px;
}
.homepage h1:first-child {
    margin-top: 7%;
}
.homepage h1 {
    font-size: 2.625rem;
    color: white;
    line-height: 1.5em;
    font-weight: 400;
    margin-left: 5%;
}
.homepage h1 span {
    font-weight: 600;
}
.homepage-buttons {
    margin-left: 3%;
}
.homepage-buttons p {
    margin-top: 2%;
    margin-left: 2%;
    color: white;
    float: left;
    padding: 0.5% 1%;
    border: solid #C1883E 2px;
    font-size: 1.625rem;
    cursor: pointer;
}
.homepage-buttons p:hover {
    animation: fill-button 0.25s forwards;
}

@keyframes fill-button {
    0% {

    }
    100% {
        background-color: #C1883E;
    }
}

@media screen and (max-width: 1366px) {
    .homepage-container {
        height: 75vh;
    }
    .homepage h1 {
        font-size: 2.1875rem;
    }
    .homepage p {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 1280px) {
    .homepage-container {
        height: 72vh;
    }
}

@media screen and (max-width: 1024px) {
    .homepage-container {
        text-align: center;
    }
    .homepage h1 {
        font-size: 1.875rem;
        margin-left: 0;
    }
    .homepage p {
        font-size: 1.3rem;
    }
}
@media screen and (max-width: 1024px) and (max-height: 1366px) {
    .homepage-container {
        text-align: center;
        height: 84vh;
    }
    .homepage h1:first-child {
        margin-top: 20%;
    }
    .homepage h1 {
        font-size: 2.1875rem;
        margin-bottom: 2%;
    }
    .homepage p {
        margin-top: 4%;
        font-size: 1.875rem;
        padding: 1.25% 2%;
        float: none;
        width: fit-content;
        margin-right: 0.5em;
        margin-left: 0.5em;
    }
    .homepage-buttons {
        display: flex;
        margin: auto;
        margin-right: 5%;
        justify-content: center;
    }
}
@media screen and (max-width: 414px) {
    .homepage-container {
        height: 80vh;
        width: 87%;
    }
    .homepage h1 {
        font-size: 1.375rem;
        line-height: 1.85em;
    }
    .homepage p {
        font-size: 1.1875rem;
        padding: 2% 3%;
        margin-top: 10%;
        border: solid #C1883E 1px;
    }
}
@media screen and (max-width: 280px) {
    .homepage h1 {
        font-size: 1.15rem;
        line-height: 1.85em;
    }
    .homepage p {
        margin-top: 10%;
        font-size: 1rem;
    }
}