.contact {
    width: 100%;
    height: 100vh;
}
.contact h1 {
    font-size: 2rem;
    text-align: center;
    margin-top: 4%;
    margin-bottom: 4%;
}
.MuiFormLabel .Mui-focused {
    color: black !important;
}
.contact form {
    text-align: center;
}
.input {
    margin-top: 1%;
}
.text-field {
    width: 25%;
}
.text-area {
    width: 25%;
}
.contact button {
    border: solid black 1px;
    background-color: white;
    font-size: 1.25rem;
    margin-top: 5%;
    padding: 0.2% .75%;
    cursor: pointer;
}
.contact button:hover {
    animation: fill-submit-button 0.4s forwards;  
}

@keyframes fill-submit-button {
    0%{

    }
    100% {
        background-color: #C1883E;
        color: white;
        border: solid #C1883E 1px;
    }
}

@media screen and (max-width: 1366px) {
    .contact h1 {
        font-size: 1.725rem;
    }
}

@media screen and (max-width: 854px) {
    .text-field {
        width: 45%;
    }
    .text-area {
        width: 45%;
    }
    .contact h1 {
        margin-top: 7%;
        margin-bottom: 7%;
    }
}

@media screen and (max-width: 414px) {
    .contact button {
        font-size: 1rem;
        padding: 0.75% 2.5%;
        margin-top: 10%;
    }
    .contact h1 {
        margin-top: 7%;
        margin-bottom: 10%;
        font-size: 1.375rem;
    }
    .contact input {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 414px) and (orientation: landscape) {
    .contact {
        height: auto;
    }
}