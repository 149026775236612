.background-shapes {
    background-image: url('../images/background-shapes.jpg');
    background-size: 100% 100%;
}

@media screen and (max-width: 854px) {
    .background-shapes {
        background-image: url('../images/background-shapes-768.jpg');
    }
}

@media screen and (max-width: 414px) {
    .background-shapes {
        background-image: url('../images/background-shapes-mobile.jpg');
    }
}