.pricing {
    width: 100%;
    height: auto;
}
.pricing h1 {
    font-size: 2rem;
    text-align: center;
    margin-top: 4%;
    margin-bottom: 4%;
}
.areas-serving {
    width: 40%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%;

    position: relative;
    left: 23%;
    transform: translate(-50%);
}
.areas-serving ul li {
    font-size: 1.5rem;
    margin-bottom: 8%;
}
.pricing p {
    text-align: center;
    margin-bottom: 1%;
}

@media screen and (max-width: 1366px) {
    .areas-serving ul li {
        font-size: 1.25rem;
    }
    .pricing h1 {
        font-size: 1.725rem;
    }
}

@media screen and (max-width: 1200px) {
    .areas-serving {
        width: 50%;
        left: 30%;
    }
}

@media screen and (max-width: 1024px) and (min-height: 940px) {
    .pricing {
        height: 100vh;
    }
}

@media screen and (max-width: 854px) {
    .pricing h1 {
        margin-top: 7%;
        margin-bottom: 7%;
    }
    .areas-serving {
        width: 70%;
        left: 44%;
        margin-bottom: 7%;
    }
    .pricing p {
        margin: auto;
        width: 70%;
        line-height: 1.5em;
        margin-bottom: 2%;
    }
}

@media screen and (max-width: 540px) {
    .areas-serving ul li{
        font-size: 0.875rem;
    }
    .pricing p {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 414px) {
    .pricing {
        height: 90vh;
    }
    .pricing h1 {
        font-size: 1.375rem;
        margin-top: 7%;
        margin-bottom: 10%;
    }
    .areas-serving {
        width: 82%;
        left: 46%;
    }
    .areas-serving ul li{
        font-size: 0.775rem;
    }
    .pricing p {
        font-size: 0.775rem;
    }
}

@media screen and (max-width: 320px) and (min-height: 568px) {
    .pricing {
        height: auto;
    }
}

@media screen and (max-width: 280px) {
    .pricing {
        height: 100vh;
    }
    .pricing h1 {
        font-size: 1.15rem;
        margin-bottom: 12%;
    }
    .areas-serving ul li{
        font-size: 0.6rem;
    }
    .pricing p {
        font-size: 0.6rem;
    }
}