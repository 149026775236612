.about {
    width: 100%;
    height: 100vh;
}
.about h1 {
    text-align: center;
    font-size: 2rem;
    margin-top: 4%;
    margin-bottom: 4%;
}
.about-content {
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    margin: auto;
    /* width: 40%; */
    width: 50%;
}
/* /* .about-photo-container {
    width: 30%;
    margin-right: 2%;
} */
/* .about img {
    width: 100%;
} */
.about img {
    width: 30%;
    margin-right: 2%;
}
.about p {
    font-size: 0.875rem;
    margin-left: 2%;
    margin-bottom: 5%;
    line-height: 1.75em;
    margin-top: 0;
    /* width: 70%; */
}

@media screen and (max-width: 1366px) {
    .about-content {
        width: 60%;
    }
    .about img {
        width: 30%;
    }
    .about h1 {
        font-size: 1.725rem;
    }
}

@media screen and (max-width: 1366px) {
    .about-content {
        width: 60%;
    }
    .about img {
        width: 30%;
    }
    .about h1 {
        font-size: 1.725rem;
    }
}

@media screen and (max-width: 1024px) {
    .about {
        height: auto;
    }
}

@media screen and (max-width: 1024px) and (max-height: 1366px) {
    .about {
        height: 100vh;
    }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
    .about {
        height: auto;
    }
    .about-content {
        display: block;
    }
    .about h1 {
        margin-top: 7%;
        margin-bottom: 7%;
    }
    .about-content {
        text-align: center;
    }
    .about-content img {
        width: 35%;
        margin-bottom: 7%;
    }
    .about-content p {
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }
}


@media screen and (max-width: 854px) {
    .about-content {
        display: block;
    }
    .about h1 {
        margin-top: 7%;
        margin-bottom: 7%;
    }
    .about-content {
        text-align: center;
    }
    .about-content img {
        width: 40%;
        margin-bottom: 7%;
    }
    .about-content p {
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 414px) {
    .about {
        height: auto;
    }
    .about h1 {
        margin-top: 7%;
        margin-bottom: 10%;
        font-size: 1.375rem;
    }
    .about-content {
        display: block;
        text-align: center;
        margin-bottom: 10%;
        width: 70%;
    }
    .about-content img {
        width: 60%;
        margin-bottom: 10%;
    }
    .about-content p {
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        font-size: 0.775rem;
    }
}

@media screen and (max-width: 414px) and (orientation: landscape){
    .about {
        height: 100vh;
    }
}